<template>
  <div>
    <div class="category-detail-breadcrumb">
      <MyBreadcrumb></MyBreadcrumb>
    </div>

    <div class="category-detail" v-if="info.category">
      <div class="category-detail-tit">题库简介</div>
      <Row class="mb">
        <Col span="18">
          <div class="category-detail-info">
            <p class="tit">题库名称：</p>
            <div><p v-for="(item,index) in info.category.categoryList" :key="item.id">{{item}}<span v-if="index < info.category.categoryList.length - 1">-</span></p></div>
            <!--        <p>{{ info.category.categoryList[0] }} - {{ info.category.categoryList[1] }} - {{ info.category.categoryList[2] }}</p>-->
          </div>
        </Col>
        <Col span="6">
          <div class="category-detail-info">
            <p>题库ID：</p>
            <p>{{ info.category.id }}</p>
          </div>
        </Col>
      </Row>
      <Row class="mb">
        <Col span="9">
          <div class="category-detail-info">
            <p class="tit">总题数：</p>
            <p>{{ info.category.data_count }}</p>
          </div>
        </Col>
        <Col span="9">
          <div class="category-detail-info">
            <p class="tit">累计作答量：</p>
            <p>{{ info.have_finished_total }}</p>
          </div>
        </Col>
        <Col span="6">
          <div class="category-detail-info">
            <p>作答正确率：</p>
            <p>{{ info.right_count_total == 0 ? 0 :(info.right_count_total / info.have_finished_total * 100).toFixed(2) }}%</p>
          </div>
        </Col>
      </Row>
      <Row class="mb">
        <Col span="24">
          <div class="category-detail-info">
            <p class="tit">难度占比：</p>
            <div><p v-for="(item,index) in info.data_level_stats" :key="index">{{ info.data_levels[item.level] }}({{ item.count }},{{ item.rate }})<span v-if="index <  info.data_level_stats.length -1">、</span></p></div>
          </div>
        </Col>
      </Row>
      <Row class="mb">
        <Col span="24">
          <div class="category-detail-info">
            <p class="tit">题型占比：</p>
            <div><p v-for="(item,index) in info.data_type_stats" :key="index">{{ info.data_types[item.type] }}({{ item.count }},{{ item.rate }})<span v-if="index <  info.data_type_stats.length -1">、</span></p></div>
          </div>
        </Col>
      </Row>
      <Row class="mb">
        <!--    <Col span="9">-->
        <!--      <div class="category-detail-info">-->
        <!--        <p class="tit">累计纠错量：</p>-->
        <!--        <p>100+</p>-->
        <!--      </div>-->
        <!--    </Col>-->
        <Col span="9">
          <div class="category-detail-info">
            <p class="tit">创建时间：</p>
            <p>{{ info.category.created_at }}</p>
          </div>
        </Col>
        <Col span="6">
          <div class="category-detail-info">
            <p class="tit">更新时间：</p>
            <p>{{ info.category.updated_at }}</p>
          </div>
        </Col>
      </Row>
      <Row class="mb">
        <Col span="9" v-if="info.data_star_count_arr['3']">
          <div class="category-detail-info">
            <div class="tit">
              <Tooltip>
                <Icon type="ios-help-circle-outline" :size="18" />
                <div slot="content">
                  <p>一般题目标准（3星）：</p>
                  <p>a、答案明确且准确（无疑问，无纠结）。</p>
                  <p>b、题目完整（题干、选项、解析）。</p>
                  <p>c、解析描述（没有官方链接）。</p>
                </div>
              </Tooltip>
              <p style="margin: 3px 0 0 5px">三星题数：</p>
            </div>
            <p><span style="margin-right: 20px">{{ info.data_star_count_arr['3'].count }}</span><span>{{ info.data_star_count_arr['3'].rate }}</span></p>
          </div>
        </Col>
        <Col span="9" v-if="info.data_star_count_arr['4']">
          <div class="category-detail-info">
            <div class="tit">
              <Tooltip>
                <Icon type="ios-help-circle-outline" :size="18" />
                <div slot="content" style="white-space: normal;">
                  <p>良好题目标准（4星）：</p>
                  <p>a、答案明确且准确（无疑问，无纠结）。</p>
                  <p>b、题目完整（题干、选项、解析）。</p>
                  <p>c、解析由  “官方链接+文字描述”  组成，针对各选项有具体的文字解答。</p>
                </div>
              </Tooltip>
              <p style="margin: 3px 0 0 5px">四星题数：</p>
            </div>
            <p><span style="margin-right: 20px">{{ info.data_star_count_arr['4'].count }}</span><span>{{ info.data_star_count_arr['4'].rate }}</span></p>
          </div>
        </Col>
        <Col span="6" v-if="info.data_star_count_arr['5']">
          <div class="category-detail-info">
            <div class="tit">
              <Tooltip>
                <Icon type="ios-help-circle-outline" :size="18" />
                <div slot="content" style="white-space: normal;">
                  <p>优秀题目标准（5星）：</p>
                  <p>a、答案明确且准确（无疑问，无纠结）。</p>
                  <p>b、题目完整（题干、选项、解析）。</p>
                  <p>c、知识点恰当、标签覆盖度完整 。</p>
                  <p>d、解析由  “官方链接+文字描述”  组成，针对各选项有具体的文字解答。</p>
                  <p>e、由专业老师校对的题。</p>
                </div>
              </Tooltip>
              <p style="margin: 3px 0 0 5px">五星题数：</p>
            </div>
            <p><span style="margin-right: 20px">{{ info.data_star_count_arr['5'].count }}</span><span>{{ info.data_star_count_arr['5'].rate }}</span></p>
          </div>
        </Col>
      </Row>
      <!--  <Row class="mb">-->
      <!--    <Col span="24">-->
      <!--      <div class="category-detail-info">-->
      <!--        <p class="tit">推荐指数：：</p>-->
      <!--        <p><Rate allow-half v-model="valueHalf" /></p>-->
      <!--      </div>-->
      <!--    </Col>-->
      <!--  </Row>-->
      <div class="category-detail-tit mt30">包含知识点（{{ info.data_node_list.length }}）</div>
      <Row class="mb">
        <Col span="24">
          <div class="category-detail-cont">
            <div v-for="item in info.data_node_list" :key="item.node_id">
              <Tooltip>
                <p class="category-detail-cont-name">{{ item.name.length <=50 ? item.name :  item.name.substring(0,50) + '...'}}</p>
                <div slot="content" style="white-space: normal;">
                  {{ item.name}}
                </div>
              </Tooltip>
              <p>{{item.count}}</p>
            </div>
          </div>
        </Col>
      </Row>
      <div class="category-detail-tit mt30">包含标签（{{ info.data_label_list.length }}）</div>
      <Row class="mb">
        <Col span="24">
          <div class="category-detail-cont">
            <div v-for="item in info.data_label_list" :key="item.label_id">
              <Tooltip>
                <p class="category-detail-cont-name">{{ item.name.length <=50 ? item.name :  item.name.substring(0,50) + '...'}}</p>
                <div slot="content" style="white-space: normal;">
                  {{ item.name }}
                </div>
              </Tooltip>
              <p>{{item.count}}</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>

</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "categoryDetail.vue",
  data(){
    return{
      categoryId:'',
      valueHalf:2.5,
      info:{},
    }
  },
  created(){
    this.categoryId = this.$route.query.id;
    this.getInfo();
  },
  methods:{
    getInfo(){
      let params = {
        action:'categoryProfile',
        category_id:this.categoryId,
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.info = res;
        let categoryList = [];
        for(let name in this.info.category.categoryList){
          categoryList.push(this.info.category.categoryList[name])
        }
        this.info.category.categoryList = categoryList;
        this.info.category.created_at = util.timeFormatter(
            new Date(+this.info.category.created_at * 1000),
            'yyyy-MM-dd hh:mm'
        );
        this.info.category.updated_at = this.info.category.updated_at == '0' ? this.info.category.created_at : util.timeFormatter(
            new Date(+this.info.category.updated_at * 1000),
            'yyyy-MM-dd hh:mm'
        );
      })
    }
  }
}
</script>

<style scoped lang="scss">
.category-detail-breadcrumb{
  margin: 20px 0 0 20px;
}
.category-detail{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  font-size: 14px;
  .category-detail-tit{
    margin-bottom: 10px;
    font-size:18px;
    font-weight: bold;
  }
  .category-detail-info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .tit{
      width: 100px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      word-break: break-word;
    }
    >div{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .category-detail-cont{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    >div{
      margin:0 10px 10px 0;
      //width: 150px;
      padding: 0 10px;
      height: 40px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      line-height: 40px;
      display: flex;
      justify-content: center;
      >p:nth-child(1),
      .category-detail-cont-name{
        margin-right: 10px;
        //width: 110px;
        //white-space: nowrap;
        //overflow:hidden;
        //text-overflow:ellipsis;
      }
    }
  }
  .mb{
    margin-bottom: 10px;
  }
  .mt30{
    margin-top: 30px;
  }
}
</style>
